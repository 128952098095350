import cloneDeep from "lodash/cloneDeep";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Input, InputNumber, Tabs } from "antd";

import { runAutoCalculationCheck } from "../utils/calculatorUtils";
import Description from "./description";
import Header from "./header";
import "./index.css";
import TestMethods from "./TestMethods";

function ImputGrid({
  moduleFieldDataSet,
  moduleDefaultFieldDataSet,
  moduleName,
  editStatus,
  handleLabVantageApiCallPopup,
  handleFieldDataUpdate,
  handleHeaderDataUpdate,
  fields,
}) {
  const defaultMetaDataFieldsList = useSelector((state) => state.defaultMetaDataFields);
  const userInfo = useSelector((state) => state.userInfo);
  const [dataSet, setDataSet] = React.useState(moduleDefaultFieldDataSet);
  const [activeRow, setActiveRow] = React.useState({});

  useEffect(() => {
    setDataSet(moduleDefaultFieldDataSet);
  }, [moduleDefaultFieldDataSet, editStatus]);

  const handleLabVantageApiCall = (item, key, module, index, fieldLabel) => {
    const newModule = module === "mechanical" || module === "absorbent" ? "material" : module;
    return handleLabVantageApiCallPopup(true, item, key, newModule, index, fieldLabel);
  };

  const fieldDataUpdate = (data) => {
    handleFieldDataUpdate(data);
  };

  const renderGridHeader = (module) => {
    return (
      <div className="row headerRow">
        <div className="col-3 col-md-3 col-sm-3">
          <span>{module}</span> Properties
        </div>
        <div className="col-5 col-md-5 col-sm-5">Values</div>
        <div className="col-1 col-md-1 col-sm-1 center">Virtual</div>
        {editStatus ? <div className="col-2 col-md-2 col-sm-2 center">Get Data</div> : null}
      </div>
    );
  };

  const checkInputRange = (finalInput, inputData, key) => {
    let tempErr = "";

    if (finalInput !== "-") {
      if (
        finalInput < inputData[key]["propertyMetaData"]["fieldMinRange"] ||
        finalInput > inputData[key]["propertyMetaData"]["fieldMaxRange"]
      ) {
        tempErr = `Value is out of range(Min: ${inputData[key]["propertyMetaData"]["fieldMinRange"]}, Max: ${inputData[key]["propertyMetaData"]["fieldMaxRange"]})`;
      }
    }
    return tempErr;
  };

  const renderArrayTypeInputField = (inputData, key, module, index) => {
    if (inputData[key]["valueTypeArray"]) {
      return (
        <Input.Group size="large" compact>
          {inputData[key]["value"].map((item, tempIndex) => {
            for (let tempKey in item) {
              return (
                <Input
                  type={item[tempKey]["propertyMetaData"]["fieldType"]}
                  readOnly={!editStatus}
                  value={item[tempKey]["value"]}
                  style={{ width: 100 / inputData[key]["value"].length + "%" }}
                  addonAfter={item[tempKey]["propertyMetaData"]["fieldUnit"]}
                  placeholder="-"
                  aria-label="-"
                  aria-describedby={`basic-${item[tempKey]["propertyMetaData"]["dbColumnName"]}`}
                  onChange={(e) => {
                    updateFieldValue(
                      item[tempKey]["propertyMetaData"]["fieldType"],
                      module,
                      inputData,
                      key,
                      index,
                      e,
                      tempIndex,
                      tempKey,
                      false
                    );
                  }}
                />
              );
            }
          })}
        </Input.Group>
      );
    }
  };

  const renderFieldValue = (inputData, key, module, index) => {
    let tempData = "";
    let finalInput = "";
    let rangeAlert = "";
    if (inputData[key]["valueTypeArray"] && inputData[key]["valueTypeArray"] !== null) {
      finalInput = tempData.substring(0, tempData.length - 2);
    } else {
      finalInput = inputData[key]["value"] !== null && inputData[key]["value"] !== "" ? inputData[key]["value"] : "-";
    }
    rangeAlert = checkInputRange(finalInput, inputData, key, module, index);

    const renderInputField = () => {
      if (inputData[key]["propertyMetaData"]["dbColumnName"] === "materialSpec") {
        return (
          <Input.TextArea
            readOnly={!editStatus}
            value={inputData[key]["value"]}
            placeholder=""
            aria-label="-"
            aria-describedby={`basic-${inputData[key]["propertyMetaData"]["dbColumnName"]}`}
            onChange={(e) => {
              updateFieldValue(
                inputData[key]["propertyMetaData"]["fieldType"],
                module,
                inputData,
                key,
                index,
                e,
                null,
                null,
                false
              );
            }}
          />
        );
      }

      if (inputData[key]["propertyMetaData"]["fieldType"] === "number") {
        return (
          <InputNumber
            size="large"
            type={inputData[key]["propertyMetaData"]["fieldType"]}
            readOnly={!editStatus}
            value={inputData[key]["value"]}
            precision={4}
            step={inputData[key]["propertyMetaData"]["fieldMinRange"] || 1}
            placeholder=""
            aria-label="-"
            aria-describedby={`basic-${inputData[key]["propertyMetaData"]["dbColumnName"]}`}
            addonAfter={inputData[key]["propertyMetaData"]["fieldUnit"]}
            keyboard={true}
            status={rangeAlert !== "" && editStatus === true ? "error" : null}
            onChange={(e) => {
              updateFieldValue(
                inputData[key]["propertyMetaData"]["fieldType"],
                module,
                inputData,
                key,
                index,
                e,
                null,
                null,
                false
              );
            }}
          />
        );
      }

      return (
        <Input
          size="large"
          type={inputData[key]["propertyMetaData"]["fieldType"]}
          disabled={!editStatus}
          value={inputData[key]["value"]}
          placeholder=""
          aria-label="-"
          aria-describedby={`basic-${inputData[key]["propertyMetaData"]["dbColumnName"]}`}
          addonAfter={inputData[key]["propertyMetaData"]["fieldUnit"]}
          onChange={(e) => {
            updateFieldValue(
              inputData[key]["propertyMetaData"]["fieldType"],
              module,
              inputData,
              key,
              index,
              e,
              null,
              null,
              false
            );
          }}
        />
      );
    };

    return (
      <div>
        {inputData[key]["valueTypeArray"] && inputData[key]["valueTypeArray"] !== null
          ? renderArrayTypeInputField(inputData, key, module, index)
          : renderInputField()}

        {rangeAlert !== "" && editStatus === true ? (
          <div className="invalid-feedback" style={{ display: "inline" }}>
            {rangeAlert}
          </div>
        ) : null}
      </div>
    );
  };

  const updateMetaData = (e, metaDatakey, module) => {
    let tempDataSet = cloneDeep(dataSet);
    let key = activeRow[module]["key"];
    const newModule = module === "mechanical" || module === "absorbent" ? "material" : module;
    let index = tempDataSet[newModule]["propertyData"].findIndex((x) => Object.keys(x)[0] === key);
    tempDataSet[newModule]["propertyData"][index][key]["dataSource"]["metaData"][metaDatakey] = e.target.value;
    setDataSet(tempDataSet);
    fieldDataUpdate(tempDataSet);
  };

  const updateFieldValue = (inputType, module, inputData, key, index, e, tempIndex, tempKey, isVirtual) => {
    let originalInput;
    let originalVirtualInput;
    let fieldValue;
    let tempDataSet = cloneDeep(dataSet);
    let tempActiveRow = cloneDeep(activeRow);
    const newModule = module === "mechanical" || module === "absorbent" ? "material" : module;
    let updatedIndex = tempDataSet[newModule]["propertyData"].findIndex((x) => Object.keys(x)[0] === key);

    tempActiveRow[module]["moduleType"] = module;
    tempActiveRow[module]["key"] = key;
    tempActiveRow[module]["index"] = index;

    let val;
    if (inputType === "number") {
      val = e;
    } else {
      val = e.target.value;
    }

    if (
      !isVirtual &&
      tempDataSet[newModule]["propertyData"][updatedIndex][key]["valueTypeArray"] &&
      tempDataSet[newModule]["propertyData"][updatedIndex][key]["valueTypeArray"] !== null
    ) {
      originalInput =
        moduleFieldDataSet[newModule]["propertyData"][updatedIndex][key]["value"][tempIndex][tempKey]["value"];
      originalVirtualInput =
        moduleFieldDataSet[newModule]["propertyData"][updatedIndex][key]["value"][tempIndex][tempKey]["virtual"];

      if (
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][tempIndex][tempKey].propertyMetaData
          .fieldType === "number"
      )
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][tempIndex][tempKey]["value"] = val;
    } else if (!isVirtual && tempDataSet[newModule]["propertyData"][updatedIndex][key]["valueTypeArray"] === false) {
      originalInput = moduleFieldDataSet[newModule]["propertyData"][updatedIndex][key]["value"];
      originalVirtualInput = moduleFieldDataSet[newModule]["propertyData"][updatedIndex][key]["virtual"];
      tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"] = val;
      if (fields["fields"][key]["targetCalculatorField"].length > 0 && fields["fields"][key]["formulaUsed"] !== null) {
        tempDataSet = runAutoCalculationCheck(tempDataSet, key, fields);
      }
    }
    if (originalInput === "-") {
      originalInput = "";
    }
    if (isVirtual) {
      originalVirtualInput = moduleFieldDataSet[newModule]["propertyData"][updatedIndex][key]["virtual"];
      originalInput = moduleFieldDataSet[newModule]["propertyData"][updatedIndex][key]["value"];
      fieldValue = tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"];
      tempDataSet[newModule]["propertyData"][updatedIndex][key]["virtual"] = !!e.target.checked;
      if (originalVirtualInput === e.target.checked && originalInput === fieldValue) {
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["edited"] = false;
        if (tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"] === undefined) {
          tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"] =
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["oldDataSource"];
        }

        if (Array.isArray(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"])) {
          Object.keys(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0]).map((nestedValKey) => {
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0][nestedValKey]["edited"] = false;
          });
        }
      } else {
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["edited"] = true;
        if (tempDataSet[newModule]["propertyData"][updatedIndex][key]["oldDataSource"] === undefined) {
          tempDataSet[newModule]["propertyData"][updatedIndex][key]["oldDataSource"] =
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"];
        }
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"] =
          defaultMetaDataFieldsList.data.default;
        if (Array.isArray(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"])) {
          Object.keys(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0]).map((nestedValKey) => {
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0][nestedValKey]["edited"] = true;
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0][nestedValKey]["dataSource"] =
              defaultMetaDataFieldsList.data.default;
          });
        }
      }

      if (!!e.target.checked === true) {
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"]["type"] = "virtual";
      } else {
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"]["type"] = "physical";
      }
      tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"]["metaData"]["author"] =
        userInfo.sAMAccountName;
    }

    if (isVirtual === false) {
      if (
        originalInput !== val &&
        (tempDataSet[newModule]["propertyData"][updatedIndex][key]["edited"] === undefined ||
          tempDataSet[newModule]["propertyData"][updatedIndex][key]["edited"] === false)
      ) {
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["edited"] = true;
        if (tempDataSet[newModule]["propertyData"][updatedIndex][key]["oldDataSource"] === undefined) {
          tempDataSet[newModule]["propertyData"][updatedIndex][key]["oldDataSource"] =
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"];
        }

        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"] =
          defaultMetaDataFieldsList.data.default;
        if (Array.isArray(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"])) {
          Object.keys(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0]).map((nestedValKey) => {
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0][nestedValKey]["dataSource"] =
              defaultMetaDataFieldsList.data.default;
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0][nestedValKey]["edited"] = true;
          });
        }
        if (tempDataSet[newModule]["propertyData"][updatedIndex][key]["virtual"] === true) {
          tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"]["type"] = "virtual";
        }

        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"]["propertyName"] = key;
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"]["metaData"]["author"] =
          userInfo.sAMAccountName;
      } else if (
        originalInput === val &&
        originalVirtualInput === tempDataSet[newModule]["propertyData"][updatedIndex][key]["virtual"]
      ) {
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["edited"] = false;
        if (Array.isArray(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"])) {
          Object.keys(tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0]).map((nestedValKey) => {
            tempDataSet[newModule]["propertyData"][updatedIndex][key]["value"][0][nestedValKey]["edited"] = false;
          });
        }
        tempDataSet[newModule]["propertyData"][updatedIndex][key]["dataSource"] =
          tempDataSet[newModule]["propertyData"][updatedIndex][key]["oldDataSource"];
      }
    }
    setActiveRow(tempActiveRow);
    setDataSet(tempDataSet);
    fieldDataUpdate(tempDataSet);
  };

  const renderVirtualCheckBox = (item, key, module, index) => {
    if (item[key]["propertyMetaData"]["fieldVirtualSupport"] && item[key]["virtual"] === true && editStatus === true) {
      return (
        <Checkbox
          checked
          id={`virtual-${key}-${index}`}
          onChange={(e) => {
            updateFieldValue("checkbox", module, item, key, index, e, null, null, true);
          }}
        />
      );
    } else if (
      item[key]["propertyMetaData"]["fieldVirtualSupport"] &&
      item[key]["virtual"] === false &&
      editStatus === true
    ) {
      return (
        <Checkbox
          checked={false}
          id={`virtual-${key}-${index}`}
          onChange={(e) => {
            updateFieldValue("checkbox", module, item, key, index, e, null, null, true);
          }}
        />
      );
    } else if (item[key]["propertyMetaData"]["fieldVirtualSupport"] && editStatus === false) {
      return <Checkbox id={`virtual-${key}-${index}`} disabled checked={item[key]["virtual"]} />;
    }
  };

  const renderAPILink = (item, key, module, index, fieldLabel) => {
    if (editStatus) {
      return (
        <div
          className="col-2 col-md-2 col-sm-2 center hover ttl"
          style={{ marginTop: "auto", marginBottom: "auto" }}
          onClick={() => handleLabVantageApiCall(item, key, module, index, fieldLabel)}
        >
          <i className="fas fa-angle-double-right"></i> API
        </div>
      );
    }
  };

  const handleClickedRow = (module, metaData, index, key) => {
    let tempData = { ...activeRow };
    if (activeRow !== null && activeRow[module]["key"] !== key) {
      tempData[module] = { moduleType: module, key: key, index: index };
      setActiveRow({ ...tempData });
    } else {
      tempData[module] = { moduleType: module, key: key, index: index };
      setActiveRow(tempData);
    }
  };

  const renderProperties = (data, module) => {
    return data[module]["propertyData"].map((item, index) => {
      for (let key in item) {
        let metaData = item[key]["propertyMetaData"];
        let activeClass = "row colspacing bg-light-row";
        if (activeRow !== null && activeRow[module] !== undefined && activeRow[module]["key"] === key) {
          activeClass = "row colspacing bg-light-row active23";
        }
        return (
          <div
            className={metaData["calculatedField"] ? "row colspacing bg-light-row disabledAll" : activeClass}
            onClick={() => {
              handleClickedRow(module, metaData, index, key);
            }}
          >
            <div className="col-3 col-md-3 col-sm-3 cursor" style={{ marginTop: "auto", marginBottom: "auto" }}>
              {metaData.fieldLabel}
            </div>
            <div className="col-5 col-md-5 col-sm-5">{renderFieldValue(item, key, module, index)}</div>
            <div className="col-1 col-md-1 col-sm-1 center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              {renderVirtualCheckBox(item, key, module, index)}
            </div>
            <>
              {(metaData["calculationForm"] || metaData["labVantageApi"] || metaData["compression"]) &&
                renderAPILink(item, key, module, index, metaData.fieldLabel)}
            </>
            <i className="fas fa-caret-right fff"></i>
          </div>
        );
      }
    });
  };

  const renderMetadataFields = (items, edited, module) => {
    if (!items || typeof items !== "object") {
      // Return a message or empty state if items is not an object
      return (
        <tr>
          <td colSpan="2">No data available</td>
        </tr>
      );
    }

    return Object.keys(items).map((key) => {
      if (items[key] !== null && typeof items[key] === "object") {
        return (
          <tr>
            <td>{key}</td>
            <table className="table table-bordered table-sm labVantageFieldsTable">
              {renderMetadataFields(items[key])}
            </table>
          </tr>
        );
      } else {
        if (edited === undefined || edited === false) {
          return (
            <tr>
              <td>{key}</td>
              <td>{items[key]}</td>
            </tr>
          );
        } else {
          let currentField = defaultMetaDataFieldsList.data.fields[key];
          if (currentField.render === true && currentField.editable === false) {
            return (
              <tr>
                <td>
                  <label>{currentField.label}</label>
                </td>
                <td>
                  <Input type={currentField.type} value={items[key]} />
                </td>
              </tr>
            );
          } else if (
            currentField.render === true &&
            currentField.editable === true &&
            currentField.type !== "textarea"
          ) {
            return (
              <tr>
                <td>
                  <label>{currentField.label}</label>
                </td>
                <td>
                  <div>
                    <Input
                      type={currentField.type}
                      style={{ background: "#fff" }}
                      value={items[key]}
                      onChange={(e) => updateMetaData(e, key, module)}
                    />
                  </div>
                </td>
              </tr>
            );
          } else if (
            currentField.render === true &&
            currentField.editable === true &&
            currentField.type === "textarea"
          ) {
            return (
              <tr>
                <td>
                  <label>{currentField.label}</label>
                </td>
                <td>
                  <div>
                    <Input.TextArea
                      style={{ background: "#fff", minWidth: "100%" }}
                      value={items[key]}
                      onChange={(e) => updateMetaData(e, key, module)}
                    />
                  </div>
                </td>
              </tr>
            );
          }
        }
      }
    });
  };

  const renderMetaData = (data, module) => {
    if (activeRow !== null) {
      if (activeRow[module] !== undefined) {
        let tempDataSet = data[module]["propertyData"][activeRow[module]["index"]][activeRow[module]["key"]];
        let edited = false;
        let tempEdit = false;
        if (tempDataSet.edited !== undefined) {
          tempEdit = cloneDeep(tempDataSet.edited);
        } else if (
          tempDataSet.edited === undefined &&
          data[module]["header"]["status"] === "Draft" &&
          (tempDataSet.dataSource.type === "physical" ||
            tempDataSet.dataSource.type === "virtual" ||
            tempDataSet.dataSource.type === "")
        ) {
          if (
            tempDataSet.dataSource.metaData !== undefined &&
            tempDataSet.dataSource.metaData.source !== undefined &&
            tempDataSet.dataSource.metaData.source !== "labvantage" &&
            tempDataSet.dataSource.metaData.source !== "Calculator"
          ) {
            tempEdit = true;
          } else {
            tempEdit = false;
          }
        }

        if (Array.isArray(tempDataSet.value)) {
          let tempKey = Object.keys(tempDataSet.value[0])[0];
          if (tempDataSet.value[0][tempKey]["edited"] !== undefined) {
            tempEdit = tempDataSet.value[0][tempKey]["edited"];
          } else {
            tempEdit = false;
          }
          tempDataSet = tempDataSet.value[0][tempKey]["dataSource"];
        } else {
          tempDataSet = tempDataSet["dataSource"];
        }
        if (tempEdit !== undefined && tempEdit === true && editStatus === true) {
          edited = true;
        } else {
          edited = false;
        }

        return (
          <div className="col-4 bg-light-gry">
            <div className="row headerRowRight">
              <div className="col-12" style={{ padding: "6px 0" }}>
                Property Details
              </div>
            </div>
            <div className="row colspacing" id="rowEdit">
              <div className="col-12">
                {tempDataSet !== undefined ? (
                  <table className="table table-bordered table-sm labVantageFieldsTable">
                    <tr>
                      <td>Type</td>
                      <td>{tempDataSet["type"]}</td>
                    </tr>
                    {renderMetadataFields(tempDataSet.metaData, edited, module, tempDataSet)}
                  </table>
                ) : (
                  <p>Details not found</p>
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const renderHeder = (module) => {
    if (module !== moduleName) {
      return (
        <div className="col-12 header-bar" style={{ marginBottom: "10px", padding: "0px" }}>
          <div className="col-12 content">
            <Header
              moduleName={module}
              moduleFieldDataSet={dataSet}
              editStatus={editStatus}
              handleHeaderDataUpdate={(data) => handleHeaderDataUpdate(data)}
            />
          </div>
        </div>
      );
    }
  };

  const renderDescription = (module) => {
    return (
      <Description
        moduleName={module}
        moduleFieldDataSet={dataSet}
        editStatus={editStatus}
        handleHeaderDataUpdate={(data) => handleHeaderDataUpdate(data)}
      />
    );
  };

  const loopProperties = () => {
    if (Object.keys(dataSet).length > 0) {
      let loopModule = [
        { id: "material", label: "Main Material Properties" },
        { id: "absorbent", label: "Absorbent Properties" },
        { id: "mechanical", label: "Mechanical Properties" },
        { id: "sam", label: "SAM Properties" },
        { id: "fiber", label: "Fiber Properties" },
      ];
      const newDataSet =
        dataSet?.material && Object.keys(dataSet?.material)?.length > 0
          ? {
              ...dataSet,
              material: {
                ...dataSet.material,
                propertyData: dataSet.material.propertyData.filter(
                  (item) =>
                    item[Object.keys(item)[0]].propertyMetaData.tab === "material" ||
                    Object.keys(item).includes("materialSpec")
                ),
              },
              absorbent: {
                header: {},
                propertyData: dataSet.material.propertyData.filter(
                  (item) => item[Object.keys(item)[0]].propertyMetaData.tab === "absorbent"
                ),
              },
              mechanical: {
                header: {},
                propertyData: dataSet.material.propertyData.filter(
                  (item) => item[Object.keys(item)[0]].propertyMetaData.tab === "mechanical"
                ),
              },
            }
          : dataSet;

      return (
        <Tabs defaultActiveKey="0" type="card">
          {loopModule.map((module, index) => {
            if (newDataSet[module.id] !== undefined && newDataSet[module.id]["propertyData"]) {
              if (activeRow != null && activeRow[module.id] === undefined) {
                let key = Object.keys(newDataSet[module.id]["propertyData"][0]);
                let tempData = { ...activeRow };
                tempData[module.id] = { moduleType: module.id, key: key[0], index: 0 };
                setActiveRow(tempData);
              }

              return (
                <Tabs.TabPane tab={module.label} key={index}>
                  <div
                    className="hover"
                    data-module={module.id}
                    style={{ display: "flex", flexWrap: "wrap", padding: "0 8px" }}
                  >
                    {Object.keys(newDataSet[module.id].header).length > 0 && renderHeder(module.id)}
                    {Object.keys(newDataSet[module.id].header).length > 0 && renderDescription(module.id)}
                    <div className="col-8 propertyInputRow">
                      {renderGridHeader(module.id)}
                      {renderProperties(newDataSet, module.id)}
                    </div>
                    {renderMetaData(newDataSet, module.id)}

                    {module.id === "mechanical" && (
                      <TestMethods
                        materialId={newDataSet.material.header.key}
                        materialName={newDataSet.material.header.name}
                        materialDescription={newDataSet.material.header.description}
                      />
                    )}
                  </div>
                </Tabs.TabPane>
              );
            }
            return <></>;
          })}
        </Tabs>
      );
    }
  };

  return (
    <div className="content" style={{ background: "#fff" }}>
      <div className="card-container">
        <div className="container-fluid headerContainer" id="cc">
          {loopProperties()}
        </div>
      </div>
    </div>
  );
}

export default ImputGrid;
